.p-tooltip {
  .p-tooltip-text {
    box-shadow: none;
    font-size: 0.875rem;
  }

  .p-tooltip-arrow {
    display: none;
  }
}
