p-card.no-shadow > .p-card {
  box-shadow: none;
}

p-card.no-padding > .p-card {
  .p-card-body,
  .p-card-content {
    padding: 0;
  }
}
