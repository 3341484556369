@mixin border-radius($val) {
  -moz-border-radius: $val;
  -webkit-border-radius: $val;
  border-radius: $val;
}

@mixin border-radius-right($val) {
  -moz-border-radius-topright: $val;
  -webkit-border-start-end-radius: $val;
  border-start-end-radius: $val;
  -moz-border-radius-bottomright: $val;
  -webkit-border-end-end-radius: $val;
  border-end-end-radius: $val;
}

@mixin border-radius-left($val) {
  -moz-border-radius-topleft: $val;
  -webkit-border-start-start-radius: $val;
  border-start-start-radius: $val;
  -moz-border-radius-bottomleft: $val;
  -webkit-border-end-start-radius: $val;
  border-end-start-radius: $val;
}

@mixin border-radius-top($val) {
  -moz-border-radius-topleft: $val;
  -webkit-border-start-start-radius: $val;
  border-start-start-radius: $val;
  -moz-border-radius-topright: $val;
  -webkit-border-start-end-radius: $val;
  border-start-end-radius: $val;
}

@mixin border-radius-bottom($val) {
  -moz-border-radius-bottomleft: $val;
  -webkit-border-end-start-radius: $val;
  border-end-start-radius: $val;
  -moz-border-radius-bottomright: $val;
  -webkit-border-end-end-radius: $val;
  border-end-end-radius: $val;
}

@mixin gradient($deg, $color1, $color2) {
  background: -moz-linear-gradient($deg, $color1 0%, $color2 100%); /* ff3.6+ */
  background: -webkit-gradient(
    linear,
    left bottom,
    right top,
    color-stop(0%, $color1),
    color-stop(100%, $color2)
  ); /* safari4+,chrome */
  background: -webkit-linear-gradient($deg, $color1 0%, $color2 100%); /* safari5.1+,chrome10+ */
  background: -o-linear-gradient($deg, $color1 0%, $color2 100%); /* opera 11.10+ */
  background: -ms-linear-gradient($deg, $color1 0%, $color2 100%); /* ie10+ */
  background: linear-gradient($deg, $color1 0%, $color2 100%); /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$color1}', endColorstr='#{$color2}',GradientType=1 ); /* ie6-9 */

  &:dir(rtl) {
    background: -moz-linear-gradient(#{-$deg}, $color1 0%, $color2 100%); /* ff3.6+ */
    background: -webkit-gradient(
      linear,
      right bottom,
      left top,
      color-stop(0%, $color1),
      color-stop(100%, $color2)
    ); /* safari4+,chrome */
    background: -webkit-linear-gradient(#{-$deg}, $color1 0%, $color2 100%); /* safari5.1+,chrome10+ */
    background: -o-linear-gradient(#{-$deg}, $color1 0%, $color2 100%); /* opera 11.10+ */
    background: -ms-linear-gradient(#{-$deg}, $color1 0%, $color2 100%); /* ie10+ */
    background: linear-gradient(#{-$deg}, $color1 0%, $color2 100%); /* w3c */
  }
}

@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

@mixin content-shadow() {
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -moz-box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
}

@mixin overlay-content-shadow() {
  -webkit-box-shadow:
    0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow:
    0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);
  box-shadow:
    0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);
}

@mixin overlay-input-shadow() {
  -webkit-box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
  -moz-box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
}

@mixin no-shadow() {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

@mixin hover-element {
  background-color: $hoverBgColor;
  color: $hoverTextColor;
}

@mixin hover-element-primary {
  background-color: $primaryLightColor;
  color: $hoverTextColor;
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie);
}

@mixin border-box-sizing() {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@mixin rotate($deg) {
  -webkit-transform: rotate($deg);
  -moz-transform: rotate($deg);
  -o-transform: rotate($deg);
  -ms-transform: rotate($deg);
  transform: rotate($deg);

  &:dir(rtl) {
    transform: rotate(#{-$deg});
  }
}

@mixin scale($deg) {
  -webkit-transform: scale($deg);
  -moz-transform: scale($deg);
  -o-transform: scale($deg);
  -ms-transform: scale($deg);
  transform: scale($deg);
}

@mixin animation($delay, $duration, $animation) {
  -webkit-animation-delay: $delay;
  -webkit-animation-duration: $duration;
  -webkit-animation-name: $animation;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-delay: $delay;
  -moz-animation-duration: $duration;
  -moz-animation-name: $animation;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  animation-delay: $delay;
  animation-duration: $duration;
  animation-name: $animation;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@mixin flex() {
  display: -ms-flexbox;
  display: flex;
}

@mixin flex-wrap($value) {
  -ms-flex-wrap: $value;
  flex-wrap: $value;
}

@mixin flex-direction-row() {
  -ms-flex-direction: row;
  flex-direction: row;
}

@mixin flex-direction-column() {
  -ms-flex-direction: column;
  flex-direction: column;
}

@mixin flex-justify-center() {
  -ms-flex-pack: center;
  justify-content: center;
}

@mixin flex-justify-between() {
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@mixin flex-align-center() {
  -ms-flex-align: center;
  align-items: center;
}

@mixin flex-align-start() {
  -ms-flex-align: start;
  align-items: flex-start;
}

@mixin flex-align-end() {
  -ms-flex-align: end;
  align-items: flex-end;
}

@mixin flex-justify-start() {
  -ms-flex-pack: start;
  justify-content: flex-start;
}

@mixin flex-justify-end() {
  -ms-flex-pack: end;
  justify-content: flex-end;
}
