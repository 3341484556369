.p-inputtext {
  &:enabled:focus {
    box-shadow:
      inset 0 0 0 1px $inputFocusBorderColor,
      inset 0 0 0 1px $inputFocusBorderColor,
      inset 0 0 0 1px $inputFocusBorderColor,
      inset 0 0 0 1px $inputFocusBorderColor;

    &.ng-invalid.ng-dirty {
      box-shadow:
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor;
    }
  }
}

.p-input-filled {
  .p-inputtext {
    @include filled-input();
  }
}
