.p-panelmenu {
  .p-panelmenu-panel {
    margin-bottom: 0;
    border-radius: 0;
    position: relative;

    .p-panelmenu-header {
      .p-panelmenu-icon {
        order: 1;
        margin-inline-start: auto;
        margin-inline-end: 0;
      }

      &.p-disabled {
        opacity: 1;

        .p-panelmenu-header-link > * {
          opacity: $disabledOpacity;
        }
      }

      &:not(.p-highlight) {
        .p-panelmenu-header-link {
          &:focus {
            background: $accordionHeaderHoverBg;
          }
        }
      }
    }
  }
}
