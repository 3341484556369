.splash-screen {
  background: #1f796d;
  width: 100%;
  height: 100%;
  position: fixed;

  .splash-loader {
    position: absolute;
    inset-inline-start: 50%;
    top: 50%;
    margin-block-start: -60px;
    margin-inline-end: 0;
    margin-block-end: 0;
    margin-inline-start: -60px;
    width: 100px;
    height: 100px;
    border-radius: 100%;

    img {
      width: 100px;
      margin-top: -10px;
      margin-inline-start: -10px;
    }

    &:after {
      content: "";
      background: transparent;
      width: 140%;
      height: 140%;
      position: absolute;
      border-radius: 100%;
      top: -20%;
      inset-inline-start: -20%;
      opacity: 0.7;
      box-shadow: rgba(240, 132, 4) -4px -5px 3px -3px;
      animation: rotate 1s infinite linear;
    }

    &:dir(rtl):after {
      box-shadow: rgba(240, 132, 4) 4px -5px 3px -3px;
      animation-name: rotate-rtl;
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
@keyframes rotate-rtl {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(-360deg);
  }
}
