$prefix: '' !default;
$separator: '\\:' !default;

$sm: 576px !default;
$md: 768px !default;
$lg: 992px !default;
$xl: 1200px !default;
$gutter: .5rem !default;

$fieldMargin: 1rem !default;
$fieldLabelMargin: .5rem !default;
$helperTextMargin: .25rem !default;

$spacer: 1rem !default;

$breakpoints: (
    'sm': $sm,
    'md': $md,
    'lg': $lg,
    'xl': $xl
) !default;