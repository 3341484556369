* {
  box-sizing: border-box;
}

.p-component {
  font-family: $fontFamily;
  font-size: $fontSize;
  font-weight: $fontWeight;

  &:dir(rtl) {
    font-family: $fontFamilyRtl;
  }
}

.p-component-overlay {
  background-color: $maskBg;
  transition-duration: $transitionDuration;
}

.p-disabled,
.p-component:disabled {
  opacity: $disabledOpacity;
}

.p-error {
  color: $errorColor;
}

.p-warning {
  color: $warningMessageTextColor;
}

.p-info {
  color: $infoMessageTextColor;
}

.p-text-secondary {
  color: $textSecondaryColor;
}

.pi {
  font-size: $primeIconFontSize;
}

.p-link {
  font-size: $fontSize;
  font-family: $fontFamily;
  border-radius: $borderRadius;

  &:dir(rtl) {
    font-family: $fontFamilyRtl;
  }

  &:focus {
    @include focused();
  }
}

.p-highlight-input {
  background-color: $highlightBg !important;
  color: $highlightTextColor !important;

  background-size:
    0 2px,
    100% 2px !important;

  background-image: linear-gradient(to bottom, #294545, #294545), linear-gradient(to bottom, #01579b, #01579b) !important;
}
