.layout-tabmenu-content {
  .inbox-tab {
    width: 250px;
    padding-bottom: 180px;
    ul {
      padding: 0 6px;
      margin: 0;
      list-style-type: none;

      li {
        padding: 8px 16px;

        img {
          float: inline-start;
          margin-inline-end: 8px;
        }

        .name {
          font-weight: bold;
          float: inline-start;
        }
        .message {
          float: inline-start;
        }
      }
    }

    .inbox-labels {
      margin-block-start: 20px;
      margin-inline-end: 6px;
      margin-block-end: 0;
      margin-inline-start: 6px;

      > span {
        font-weight: bold;
      }

      ul {
        margin-top: 10px;

        li {
          padding: 6px;

          .inbox-label-badge {
            color: $accentTextColor;
            background-color: $accentColor;
            padding: 2px 6px;
            @include border-radius(3px);
            float: inline-end;
            margin-top: -3px;
          }
        }
      }
    }
  }

  .calendar-tab {
    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;

      li {
        padding: 8px 16px;

        .calendar-event-date {
          float: inline-start;
          width: 48px;
          height: 48px;
          text-align: center;
          padding-block-start: 6px;
          padding-inline-end: 0;
          padding-block-end: 0;
          padding-inline-start: 0;
          font-size: 14px;
          font-weight: 700;
          box-sizing: border-box;
          margin-inline-end: 8px;
          background-color: $accentColor;
          color: $accentTextColor;

          span {
            width: 100%;
            display: inline-block;
          }
        }

        .calendar-event-detail {
          float: inline-start;

          .calendar-event-title {
            font-weight: 700;
            display: block;
          }

          i {
            margin-inline-end: 4px;
            margin-top: 2px;
          }

          .calendar-event-location {
            position: relative;
          }

          .calendar-event-rsvp {
            display: block;

            &.calendar-event-rsvp-yes {
              color: #35ae47;
            }

            &.calendar-event-rsvp-maybe {
              color: #f5a623;
            }
          }
        }
      }
    }
  }

  .projects-tab {
    padding-bottom: 180px;
    ul {
      width: 250px;
      padding: 8px 16px;
      margin: 0;
      list-style-type: none;

      li {
        padding: 12px 0;

        i {
          font-size: 30px;
          margin-inline-end: 12px;
          float: inline-start;
          width: 32px;
          color: $textSecondaryColor;
        }

        .project-title {
          font-weight: 700;
        }

        span {
          float: inline-start;
          display: block;
        }

        .project-progressbar {
          width: 100px;
          float: inline-start;
          background-color: #545b61;
          margin-top: 4px;
          margin-inline-start: 42px;

          .project-progressbar-value {
            background-color: $accentColor;
            height: 4px;
          }
        }
      }
    }
  }

  .team-tab {
    ul {
      padding: 8px 16px;
      margin: 0;
      list-style-type: none;

      li {
        padding: 6px 0;

        img {
          float: inline-start;
          margin-inline-end: 8px;
        }

        .name {
          font-weight: bold;
          float: inline-start;
        }
        .location {
          float: inline-start;
        }

        span {
          display: block;
        }
      }
    }
  }
}

.layout-sidebar-dark {
  .layout-tabmenu-content {
    color: $darkMenuItemTextColor;
  }
}
