.p-panelmenu {
  a {
    text-decoration: none;
  }

  .p-panelmenu-header {
    outline: 0 none;

    .p-panelmenu-header-content {
      border: $accordionHeaderBorder;
      color: $accordionHeaderTextColor;
      background: $accordionHeaderBg;
      transition: $listItemTransition;

      .p-panelmenu-header-action {
        color: $accordionHeaderTextColor;
        padding: $accordionHeaderPadding;
        font-weight: $accordionHeaderFontWeight;

        .p-submenu-icon {
          margin-inline-end: $inlineSpacing;
        }

        .p-menuitem-icon {
          margin-inline-end: $inlineSpacing;
        }

        .p-menuitem-badge {
          margin-inline-start: 0.25rem;

          &:not(.no-style) {
            background-color: $accentColor;
            color: $accentTextColor;
            padding: 2px 4px;
            border-radius: 3px;
            font-size: $fontSize;
            font-weight: bold;
            margin-inline-start: 0.5rem;
          }
        }
      }
    }

    &:not(.p-disabled) {
      &:focus-visible {
        .p-panelmenu-header-content {
          @include focused();
        }
      }
    }

    &:not(.p-disabled):hover {
      .p-panelmenu-header-content {
        background: $accordionHeaderHoverBg;
        border-color: $accordionHeaderHoverBorderColor;
        color: $accordionHeaderTextHoverColor;
      }
    }

    .p-panelmenu-header-action.p-menuitem-link-active {
      background: $panelMenuHeaderActiveBg;
      border-color: $panelMenuHeaderActiveBorderColor;
      color: $panelMenuHeaderTextActiveColor;
    }
  }

  .p-panelmenu-content {
    border: $accordionContentBorder;
    background: $accordionContentBg;
    color: $accordionContentTextColor;
    padding-inline-start: $panelMenuSecondLevelPadding;
    border-top: 0;

    .p-panelmenu-root-list {
      outline: 0 none;
    }

    .p-menuitem {
      > .p-menuitem-content {
        color: $menuitemTextColor;
        transition: $listItemTransition;

        .p-menuitem-link {
          color: $menuitemTextColor;
          padding: $accordionHeaderPadding;
          user-select: none;

          .p-menuitem-text {
            color: $menuitemTextColor;
          }

          .p-menuitem-icon {
            color: $menuitemIconColor;
            margin-inline-end: $inlineSpacing;
          }

          .p-submenu-icon {
            color: $menuitemIconColor;
          }
        }
      }

      &.p-highlight {
        > .p-menuitem-content {
          color: $menuitemTextActiveColor;
          background: $menuitemActiveBg;

          .p-menuitem-link {
            .p-menuitem-text {
              color: $menuitemTextActiveColor;
            }

            .p-menuitem-icon,
            .p-submenu-icon {
              color: $menuitemIconActiveColor;
            }
          }
        }

        &.p-focus {
          > .p-menuitem-content {
            background: $menuitemActiveBg;
          }
        }
      }

      &:not(.p-highlight):not(.p-disabled) {
        &.p-focus {
          > .p-menuitem-content {
            color: $horizontalMenuRootMenuitemTextActiveColor;
            background: $menuitemActiveBg;

            .p-menuitem-link {
              .p-menuitem-text {
                color: $horizontalMenuRootMenuitemTextActiveColor;
              }

              .p-menuitem-icon,
              .p-submenu-icon {
                color: $horizontalMenuRootMenuitemIconActiveColor;
              }
            }
          }
        }

        > .p-menuitem-content {
          &:hover {
            color: $menuitemTextHoverColor;
            background: $menuitemHoverBg;

            .p-menuitem-link {
              .p-menuitem-text {
                color: $menuitemTextHoverColor;
              }

              .p-menuitem-icon,
              .p-submenu-icon {
                color: $menuitemIconHoverColor;
              }
            }
          }
        }
      }

      .p-menuitem-content {
        .p-menuitem-link {
          .p-submenu-icon {
            margin-inline-end: $inlineSpacing;
          }
        }
      }
    }

    .p-menuitem-separator {
      border-top: $divider;
      margin: $menuSeparatorMargin;
    }

    .p-submenu-list:not(.p-panelmenu-root-list) {
      padding: $treeNodeChildrenPadding;
    }
  }

  .p-panelmenu-panel {
    margin-bottom: $accordionSpacing;

    @if $accordionSpacing == 0 {
      &:not(:first-child) {
        .p-panelmenu-header {
          .p-panelmenu-header-content {
            border-top: 0 none;
          }

          &:not(.p-highlight):not(.p-disabled):hover,
          &:not(.p-disabled).p-highlight:hover {
            .p-panelmenu-header-content {
              border-top: 0 none;
            }
          }
        }
      }
    }
  }
}
