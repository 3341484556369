@use "sass:color";

$header-height: 50px;
$footer-height: 50px;
$layout-inner-padding: 5px;

.viewer-backdrop {
  background-color: white !important;
}

.p-dynamic-dialog {
  background-color: white;
}

.layout-wrapper .layout-main {
  height: 100vh;
  padding: #{$header-height + $layout-inner-padding} $layout-inner-padding #{$footer-height + $layout-inner-padding}
    $layout-inner-padding;

  @media screen and (max-width: 1024px) {
    margin-inline-start: 0 !important;
  }
}

.layout-wrapper .layout-sidebar {
  top: $header-height;
  bottom: $footer-height;
  height: auto;
}

.layout-wrapper .footer {
  margin-inline-start: 0 !important;
}

.cursor-copy {
  cursor: copy !important;
}

/* Do not use these styles! They are trying to fix already broken tables, e.g. with a layout like tr > div */
.p-datatable.p-bad-datatable {
  display: flex;
  flex-direction: column;

  > .p-datatable-wrapper {
    flex: 1;
    overflow: auto;
  }

  table {
    font-size: 1rem;
  }

  &.p-datatable-scrollable {
    .p-datatable-thead,
    .p-datatable-tbody,
    .p-datatable-tfoot {
      display: block;

      > tr {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;

        > td,
        > th {
          display: flex;
          flex: 1 1 0;
          align-items: center;
        }
      }
    }
  }
}

.p-treetable {
  .p-treetable-scrollable-header,
  .p-treetable-scrollable-footer {
    overflow: visible;
  }
}

.p-treetable.p-bad-treetable {
  display: flex;
  flex-direction: column;

  > .p-treetable-wrapper {
    flex: 1;
    overflow: auto;
  }

  table {
    font-size: 1rem;
  }

  > .p-treetable-scrollable-wrapper {
    overflow: auto;
  }

  .p-treetable-scrollable-header,
  .p-treetable-scrollable-footer {
    overflow: visible;
  }

  &.p-treetable-flex-scrollable {
    .p-treetable-thead,
    .p-treetable-tbody,
    .p-treetable-tfoot {
      display: block;

      > tr {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;

        > td,
        > th {
          display: flex;
          flex: 1 1 0;
          align-items: center;
        }
      }
    }
  }
}
/* . */

/* Proper alignment of the column filter and sort icons */
.p-datatable .p-sortable-column {
  p-columnfilter {
    vertical-align: middle;

    filtericon {
      line-height: 1;
    }
  }

  p-sorticon {
    vertical-align: middle;

    > .p-icon-wrapper {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      height: 2rem;

      > .p-sortable-column-icon {
        margin-inline-start: 0;
      }
    }
  }
}
/* . */

.app-form,
.p-card,
.p-card-body,
.p-card-content,
.p-datatable-scrollable-wrapper,
.p-treetable-scrollable-wrapper,
.p-tabview,
.p-tabview-panels,
.p-sidebar.sidebar-with-table > .p-sidebar-content {
  height: 100%;
  min-height: 1px;
}

.p-tabview-panel {
  height: 100%;
  min-height: 1px;
  position: relative;
}

.p-card-body {
  display: flex;
  flex-direction: column;

  .p-card-content {
    flex: 1;
  }
}

.p-tabview-panels,
.app-content,
.p-sidebar-right {
  overflow: auto;
}

.p-progressbar {
  height: 20px;
}

.p-progressbar-label {
  line-height: 20px;
  font-weight: bold;
  color: $accentTextColor;
  text-shadow: 0px 0px 1px $overlayColor;
}

.field {
  margin-bottom: $fieldMargin;
}

.p-progressbar-wrapper {
  position: relative;

  .p-progressbar-label {
    position: absolute;
    top: 0;
    bottom: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    text-align: center;
  }
}

.p-dialog-content {
  height: 100%;
}

.p-sidebar .p-sidebar-content {
  overflow: initial;
  flex: 1;
}

.p-tooltip.p-tooltip-fluid {
  max-width: none;

  ul {
    padding: 0 1rem;
    margin: 0;
  }
}

.p-paginator {
  display: flex;
  justify-content: center;
}

.p-picklist-paginator {
  margin-inline-end: calc(#{$buttonIconOnlyWidth} + #{$panelContentPadding} * 2);
}

.p-fileupload {
  table {
    width: 100%;
  }

  .p-fileupload-content {
    padding: 1rem 1rem;

    &.p-fileupload-highlight {
      outline: 3px dashed $primaryColor;
      outline-offset: -3px;
      border: 1px solid transparent;
      background: $highlightBg;

      * {
        pointer-events: none;
      }
    }

    .p-fileupload-prompt-wrapper {
      position: relative;
      padding: 2rem 0;
    }

    .p-fileupload-prompt {
      color: $textSecondaryColor;
      user-select: none;
      position: absolute;
      top: 50%;
      inset-inline-start: 50%;
      transform: translate(-50%, -50%);

      &:dir(rtl) {
        transform: translate(50%, -50%);
      }
    }
  }

  &.p-fileupload-advanced .p-message {
    position: relative;
    z-index: 1;
  }
}

.ql-tooltip {
  inset-inline-start: 0 !important;
}

.ql-container.ql-disabled {
  opacity: 0.5;

  & > .ql-editor {
    background: #f5f5f5;
  }
}

/* hahaha caveman code https://github.com/slab/quill/issues/4103 */
:not(.p-editor-content) > .ql-editor li {
  list-style-type: unset !important;
  padding-left: unset !important;
}

.p-editor-disabled .p-editor-toolbar {
  opacity: 0.5;
}

.navigation-menu li a {
  i:first-child {
    float: inline-start !important;
  }

  span {
    display: inline !important;
  }
}

.p-datatable .p-sortable-column {
  white-space: nowrap;

  &:hover,
  &:focus {
    background: $inputFilledBg !important;
  }
}

.p-tabview-nav {
  li.p-error {
    a span {
      color: red;
    }

    &.p-highlight ~ .p-tabview-ink-bar {
      background: red;
    }
  }

  li {
    .p-tabview-nav-link {
      text-decoration: none;
    }
  }
}

.p-fieldset.p-fieldset-sm .p-fieldset-content {
  padding-top: 0;
}

.p-tabview.p-tabview-scrollable {
  > .p-tabview-nav-container {
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    overflow: visible;
    border: solid rgba(0, 0, 0, 0.12);
    border-width: 0 0 1px 0;

    > .p-tabview-nav-content > .p-tabview-nav {
      border: none;
    }
  }
}

.p-dialog-mask {
  background: rgba(0, 0, 0, 0.5);
  pointer-events: all;
}

.p-inputgroup {
  p-dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > .p-element:first-child > .p-dropdown {
    border-start-end-radius: 0;
    border-end-end-radius: 0;
  }

  & > .p-element:last-child > .p-dropdown {
    border-start-start-radius: 0;
    border-end-start-radius: 0;
  }
}

.p-sidebar-full,
.p-overflow-auto {
  overflow: auto;
}

.p-menu {
  &.p-menu-wide {
    width: 25rem;
  }
}

.p-menuitem-link,
.p-accordion-header-link {
  text-decoration: none;
}

.p-divider {
  &.p-divider-horizontal:before {
    border-top-style: solid;
  }

  &.p-divider-vertical:before {
    border-inline-start-style: solid;
  }
}

.p-buttonset {
  .p-button:first-of-type:not(:only-of-type),
  p-button:first-of-type:not(:only-of-type) .p-button {
    border-start-end-radius: 0;
    border-end-end-radius: 0;
  }

  .p-button:not(:first-of-type):not(:last-of-type),
  p-button:not(:first-of-type):not(:last-of-type) .p-button {
    border-radius: 0;
  }

  .p-button:last-of-type:not(:only-of-type),
  p-button:last-of-type:not(:only-of-type) .p-button {
    border-start-start-radius: 0;
    border-end-start-radius: 0;
  }
}

.p-fluid .p-fileupload .p-button {
  width: auto;
}

.p-component {
  &.minimalistic {
    &.p-dropdown {
      background: none !important;

      > .p-dropdown-label {
        padding: 0;
      }

      > .p-dropdown-trigger {
        margin-inline-start: 4px;
        width: auto;
      }
    }

    &.p-multiselect {
      background: none !important;

      .p-multiselect-label {
        padding: 0;
      }

      .p-multiselect-trigger {
        margin-inline-start: 4px;
        width: auto;
      }
    }

    &.p-inputtext {
      background: none !important;
    }

    &.p-calender {
      background: none !important;
    }
  }

  &.compact {
    &.p-dropdown {
      > .p-dropdown-label {
        padding: 0;
      }
    }

    &.p-inputtext {
      padding: 0;
    }

    &.p-calendar {
      > .p-inputtext,
      .p-datepicker-trigger {
        padding: 0;
      }
    }
  }
}

.p-multiselect-label-container {
  display: flex;
}

// https://github.com/primefaces/primeng/issues/15726
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  &[data-p-highlight="true"],
  &:not(.p-highlight):not(.p-disabled):hover {
    color: $highlightTextColor;
    background: $highlightBg;
  }

  &:not([data-p-highlight="true"]):not(.p-disabled):hover {
    color: $inputListItemTextHoverColor;
    background: $inputListItemHoverBg;
  }
}

.p-inputnumber-buttons-stacked .p-button {
  &.p-inputnumber-button-up,
  &.p-inputnumber-button-down {
    padding: 0;
    border-start-start-radius: 0;
    border-end-start-radius: 0;
  }

  &.p-inputnumber-button-up {
    border-end-end-radius: 0;
  }

  &.p-inputnumber-button-down {
    border-start-end-radius: 0;
  }
}

.p-autocomplete-dd {
  .p-autocomplete-dropdown {
    border-start-start-radius: 0;
    border-end-start-radius: 0;
  }
}

.p-splitbutton {
  .p-splitbutton-defaultbutton {
    border-start-end-radius: 0;
    border-end-end-radius: 0;
  }

  .p-splitbutton-menubutton {
    border-start-start-radius: 0;
    border-end-start-radius: 0;
  }
}

.p-overlaypanel {
  &:before {
    border-width: 10px;
  }

  &:after {
    border-width: 8px;
  }

  &.p-overlaypanel-flipped {
    &:after,
    &:before {
      border-bottom: none;
    }
  }
}

.p-multiselect,
.p-calendar {
  position: relative;

  .p-multiselect-clear-icon,
  .p-calendar-clear-icon {
    position: absolute;
    inset-inline-end: 2.2rem;
    color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
  }

  .p-multiselect-label {
    padding-inline-end: 1.75rem;
  }
}

.p-calendar-disabled {
  opacity: 0.38;

  .p-component {
    opacity: 1;
  }

  &:hover {
    background-image:
      linear-gradient(to bottom, #294545, #294545), linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38)) !important;
    background-color: #f5f5f5 !important;
  }

  .p-datepicker-trigger {
    background-color: transparent !important;
  }
}

.p-datepicker {
  min-width: 200px;
}

.p-datepicker-monthpicker {
  min-width: 330px;
}

.p-datepicker-calendar td > span.p-disabled {
  opacity: 0.6;
}

.p-cascadeselect {
  .p-cascadeselect-item-active > .p-cascadeselect-sublist > .p-cascadeselect-panel {
    max-height: 400px;
    overflow-y: auto;
  }
}

.p-button.p-button-icon-only.p-button-active {
  &,
  &:hover,
  &:focus,
  &:active {
    background: $highlightBg;
    color: $highlightTextColor;
  }
}

/* there seem to be no styles for large icon only buttons; perhaps, because of the theme and primeng version mismatch */
.p-button.p-button-icon-only.p-button-lg {
  padding: 1rem;
  width: auto;
  height: auto;

  > .pi {
    font-size: 1.5rem;
  }
}

table {
  overflow-wrap: anywhere;
}

textarea {
  resize: vertical;
}

body a {
  text-decoration: underline;
}

.exception-body {
  background: $primaryColor !important;
  height: 100vh;
  width: 100vw;
}

.exception-logo {
  position: absolute;
  top: 150px;
  inset-inline-start: 50%;
  transform: translate(-50%, 0);

  &:dir(rtl) {
    transform: translate(50%, 0);
  }

  .logo {
    display: block;
    white-space: nowrap;

    .logo-img {
      width: 35px;
      vertical-align: middle;
      margin-inline-end: 10px;
    }

    .logo-txt {
      height: 25px;
      vertical-align: middle;
    }
  }
}

.layout-tabmenu-nav-bottom {
  position: absolute;
  bottom: 0;
  inset-inline-start: 0;
}

.pixo-wrapper {
  z-index: 99999;
}

.button-alignment {
  padding: $buttonPadding;
}

.mw-1 {
  min-width: 1px;
}

.p-datatable-header .p-input-icon-left {
  min-width: 1px;

  & > .p-inputtext {
    width: 100%;
  }
}

.formly-inputgroup {
  .p-inputgroup-addon {
    padding-top: 0;
    padding-bottom: 0;
  }

  .p-inputtext {
    width: 100% !important;
    border-start-start-radius: 0 !important;
  }
}

.p-blockui.p-component-overlay {
  background: rgba(0, 0, 0, 0.12) !important;

  .block-ui-message {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 2rem;
    padding: 1rem 2rem;
    backdrop-filter: blur(2px);
  }
}

.pi-ankaadia {
  background-image: url(../../../assets/layout/images/logo.png);
  background-size: 16px 16px;
  width: 16px;
  height: 16px;
}

.overflow-card > .p-card-body > .p-card-content {
  overflow-y: auto;
}

/* copy of #topbar-menu-button */
.layout-wrapper .topbar #topbar-menu-button-2 {
  font-size: 28px;
  margin-block-start: 12px;
  margin-inline-end: 20px;
  margin-block-end: 0;
  margin-inline-start: 0;
  display: none;
  float: inline-end;
  color: $primaryTextColor;
  @include transition(color 0.3s);

  @media (max-width: 1024px) {
    display: block;
    i {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .p-tabview.p-tabview-mobile {
    .p-tabview-nav {
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: auto;
      flex-shrink: 0;

      .p-tabview-nav-link {
        white-space: nowrap;
      }

      .p-tabview-ink-bar {
        display: none;
      }
    }
  }

  .p-dialog.p-dialog-compact {
    .p-dialog-header {
      padding-block-start: 1rem;
      padding-inline-end: 1rem;
      padding-block-end: 0.25rem;
      padding-inline-start: 1rem;
    }

    .p-dialog-content {
      padding-block-start: 0;
      padding-inline-end: 0.5rem;
      padding-block-end: 0.5rem;
      padding-inline-start: 0.5rem;
    }
  }

  .layout-wrapper .topbar #topbar-menu-button,
  .layout-wrapper .topbar #topbar-menu-button-2 {
    margin-top: 7px;
  }

  .p-overlaypanel {
    width: 100% !important;
  }

  .p-fileupload table {
    display: flex;
    flex-direction: column;

    tr {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 1rem 0;

      &:not(:last-child) {
        border-bottom: 1px solid $panelHeaderHoverBorderColor;
      }

      td {
        &:nth-child(1) {
          flex: 0 0 100%;
        }

        &:nth-child(2) {
          display: none;
        }

        &:nth-child(3) {
          flex: 1 1 auto;
          max-width: 100%;
        }

        &:nth-child(4) {
          align-self: center;
        }
      }
    }
  }
}

.computer-only {
  display: block;

  @media screen and (max-width: 1024px) {
    display: none !important;
  }
}

.mobile-only {
  display: none;

  @media screen and (max-width: 1024px) {
    display: block !important;
  }
}

@media screen and (max-width: 64em) {
  .p-sidebar.p-sidebar-left.p-sidebar-lg,
  .p-sidebar.p-sidebar-left.p-sidebar-md,
  .p-sidebar.p-sidebar-right.p-sidebar-lg,
  .p-sidebar.p-sidebar-right.p-sidebar-md {
    width: 100%;
  }

  .p-paginator {
    padding-inline-start: 0;
    padding-inline-end: 0;

    .p-paginator-first,
    .p-paginator-prev,
    .p-paginator-next,
    .p-paginator-last {
      margin: 0;
    }
  }
}

.employer-profile-form-card {
  .galary-left-section {
    p-fieldset {
      fieldset {
        height: 100%;
      }
    }
  }
  .p-card-content {
    display: flex;
    flex-wrap: wrap;
  }
}

img.flag,
span.flag {
  width: 30px !important;
  height: 20px !important;

  &.flag-small {
    width: 21px !important;
    height: 14px !important;
  }
}

.noresize {
  resize: none;
}

.layout-wrapper
  .layout-sidebar
  .layout-tabmenu
  .layout-tabmenu-contents
  .layout-tabmenu-content
  .layout-submenu-content
  .navigation-menu {
  li a {
    position: relative;
    padding-block-start: 8px;
    padding-inline-end: 64px;
    padding-block-end: 8;
    padding-inline-start: 16px;
  }

  .menuitem-badge {
    inset-inline-end: 15px;
    top: 7px;
    padding: 2px 4px;
    border-radius: 3px;
    font-size: 14px;
    line-height: 1;
    width: auto;
    height: auto;
  }
}

.text-pre-wrap {
  white-space: pre-wrap;
}

p-tabview.vertical {
  .p-tabview-nav {
    flex-direction: column;
    border-width: 0 0 0 0;

    .p-tabview-ink-bar {
      height: 0px;
      background-color: transparent;
    }
  }

  .p-tabview {
    display: flex;
    flex-direction: row;
  }

  .p-tabview-panels {
    width: 100%;
  }
}

p-tabview.horizontal {
  .p-tabview-nav {
    flex-direction: row;
    border-width: 0 0 0 0;

    .p-tabview-ink-bar {
      height: 0px;
      background-color: transparent;
    }
  }

  .p-tabview {
    display: flex;
    flex-direction: column;
  }

  .p-tabview-panels {
    width: 100%;
  }
}

.p-splitter.no-border {
  border: none;
}

.alignment-checkbox {
  visibility: hidden;
  width: 0;

  & + label {
    margin-inline-start: 0;
  }
}

/* fixing badges inside tab names */
.p-tabview-nav-link .p-badge {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}
/* /fixing badges inside tab names */

/* readonly "styling" */
p-dropdown[ng-reflect-readonly="true"] > .p-dropdown,
p-multiselect[ng-reflect-readonly="true"] > .p-multiselect,
p-calendar[ng-reflect-readonly="true"] > .p-calendar,
.p-inputtext[readonly] {
  &,
  &:hover {
    background-image: none !important;
  }
}
/* /readonly "styling" */

a.clickable {
  cursor: pointer;
}

.exception-body {
  &.accessdenied-body {
    .exception-panel {
      .exception-extra {
        color: #f57c00;
        margin-block-start: 110px;
        margin-inline-end: 0;
        margin-block-end: 0;
        margin-inline-start: 30px;
      }
    }
  }
}

.visibility-hidden {
  visibility: hidden;
}

.color-info {
  color: color.adjust($infoMessageBg, $lightness: -30%);
}

.color-success {
  color: color.adjust($successMessageBg, $lightness: -30%);
}

.color-warning {
  color: color.adjust($warningMessageBg, $lightness: -30%);
}

.color-error {
  color: color.adjust($errorMessageBg, $lightness: -30%);
}

.color-inherit {
  color: inherit !important;
}

// for some reason PrimeFlex ends at 30rem
.max-w-36rem {
  max-width: 36rem;
}

// for some reason PrimeFlex does not include min-w- counterparts
.min-w-30rem {
  min-width: 30rem;
}

// for some reason PrimeNG hides everything in the print mode
@media print {
  body * {
    visibility: initial;
  }
}

.p-colorpicker-panel .p-colorpicker-color {
  background: transparent url("../../primeng/color-picker-color.png") no-repeat left top;
}

.p-colorpicker-panel .p-colorpicker-hue {
  background: transparent url("../../primeng/color-picker-hue.png") no-repeat left top !important;
}
