.p-selectbutton {
  .p-button {
    &:focus {
      background: $toggleButtonActiveBg;
      border-color: $toggleButtonActiveBorderColor;

      &.p-highlight {
        background: $toggleButtonActiveHoverBg;
        border-color: $toggleButtonActiveHoverBorderColor;
      }
    }
  }
}
