.p-input-filled {
  .p-inputgroup {
    .p-inputgroup-addon {
      @include filled-input-default();

      &:last-child {
        border-inline-end-color: transparent;
      }
    }
  }

  .p-inputgroup-addon:first-child,
  .p-inputgroup button:first-child,
  .p-inputgroup input:first-child {
    border-end-start-radius: 0;
  }

  .p-inputgroup .p-float-label:first-child input {
    border-end-start-radius: 0;
  }

  .p-inputgroup-addon:last-child,
  .p-inputgroup button:last-child,
  .p-inputgroup input:last-child {
    border-end-end-radius: 0;
  }

  .p-inputgroup .p-float-label:last-child input {
    border-end-end-radius: 0;
  }
}
