.#{$prefix}field {
    margin-bottom: $fieldMargin;
}

.#{$prefix}field > label {
    display: inline-block;
    margin-bottom: $fieldLabelMargin;
}

.#{$prefix}field.#{$prefix}grid > label {
    display: flex;
    align-items: center;
}

.#{$prefix}field > small {
    margin-top: $helperTextMargin;
}

.#{$prefix}field.#{$prefix}grid,
.#{$prefix}formgrid.#{$prefix}grid {
    margin-top: 0;
}

.#{$prefix}field.#{$prefix}grid .#{$prefix}col-fixed,
.#{$prefix}formgrid.#{$prefix}grid .#{$prefix}col-fixed,
.#{$prefix}field.#{$prefix}grid .#{$prefix}col,
.#{$prefix}formgrid.#{$prefix}grid .#{$prefix}col,
.#{$prefix}field.#{$prefix}grid .#{$prefix}col-1, 
.#{$prefix}formgrid.#{$prefix}grid .#{$prefix}col-1, 
.#{$prefix}field.#{$prefix}grid .#{$prefix}col-2,
.#{$prefix}formgrid.#{$prefix}grid .#{$prefix}col-2, 
.#{$prefix}field.#{$prefix}grid .#{$prefix}col-3, 
.#{$prefix}formgrid.#{$prefix}grid .#{$prefix}col-3, 
.#{$prefix}field.#{$prefix}grid .#{$prefix}col-4, 
.#{$prefix}formgrid.#{$prefix}grid .#{$prefix}col-4, 
.#{$prefix}field.#{$prefix}grid .#{$prefix}col-5, 
.#{$prefix}formgrid.#{$prefix}grid .#{$prefix}col-5, 
.#{$prefix}field.#{$prefix}grid .#{$prefix}col-6, 
.#{$prefix}formgrid.#{$prefix}grid .#{$prefix}col-6,
.#{$prefix}field.#{$prefix}grid .#{$prefix}col-7, 
.#{$prefix}formgrid.#{$prefix}grid .#{$prefix}col-7, 
.#{$prefix}field.#{$prefix}grid .#{$prefix}col-8, 
.#{$prefix}formgrid.#{$prefix}grid .#{$prefix}col-8, 
.#{$prefix}field.#{$prefix}grid .#{$prefix}col-9, 
.#{$prefix}formgrid.#{$prefix}grid .#{$prefix}col-9, 
.#{$prefix}field.#{$prefix}grid .#{$prefix}col-10, 
.#{$prefix}formgrid.#{$prefix}grid .#{$prefix}col-10, 
.#{$prefix}field.#{$prefix}grid .#{$prefix}col-11, 
.#{$prefix}formgrid.#{$prefix}grid .#{$prefix}col-11, 
.#{$prefix}field.#{$prefix}grid .#{$prefix}col-12,
.#{$prefix}formgrid.#{$prefix}grid .#{$prefix}col-12 {
    padding-top: 0;
    padding-bottom: 0;
}

.#{$prefix}formgroup-inline {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.#{$prefix}formgroup-inline .#{$prefix}field,
.#{$prefix}formgroup-inline .#{$prefix}field-checkbox,
.#{$prefix}formgroup-inline .#{$prefix}field-radiobutton {
    margin-inline-end: $fieldMargin;
}

.#{$prefix}formgroup-inline .#{$prefix}field > label,
.#{$prefix}formgroup-inline .#{$prefix}field-checkbox > label,
.#{$prefix}formgroup-inline .#{$prefix}field-radiobutton > label {
    margin-inline-end: $fieldLabelMargin;
    margin-bottom: 0;
}

.#{$prefix}field-checkbox,
.#{$prefix}field-radiobutton {
    margin-bottom: $fieldMargin;
    display: flex;
    align-items: center;
}

.#{$prefix}field-checkbox > label,
.#{$prefix}field-radiobutton > label {
    margin-inline-start: $fieldLabelMargin;
    line-height: 1;
}
