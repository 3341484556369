@media (min-width: 1025px) {
  .layout-wrapper {
    &.layout-wrapper-menu-active {
      .layout-sidebar {
        width: 310px;

        .layout-tabmenu {
          .layout-tabmenu-contents {
            display: block;
          }
        }
      }

      .layout-main {
        margin-inline-start: 310px;
      }

      .footer {
        margin-inline-start: 310px;
      }
    }

    &.layout-overlay-menu {
      &.layout-wrapper-menu-active {
        .layout-main {
          margin-inline-start: 65px;
        }
        .footer {
          margin-inline-start: 65px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .layout-wrapper {
    .topbar {
      #topbar-menu-button {
        display: block;

        i {
          font-size: 18px;
        }
      }

      .topbar-menu {
        position: absolute;
        top: 60px;
        inset-inline-end: 15px;
        width: 250px;
        -webkit-animation-duration: 0.5s;
        -moz-animation-duration: 0.5s;
        animation-duration: 0.5s;
        display: none;
        background-color: $topbarSubmenuBgColor;
        list-style-type: none;
        margin: 0;
        padding: 8px 0;
        @include overlay-content-shadow();

        > li {
          box-sizing: border-box;
          width: 100%;
          margin: 0;
          float: none;

          > a {
            font-size: $fontSize;
            width: 100%;
            display: block;
            box-sizing: border-box;
            color: $textColor;
            padding: $listItemPadding;
            position: relative;
            @include transition(background-color 0.3s);

            .topbar-icon {
              display: inline-block;
              vertical-align: middle;
              margin-inline-end: 8px;
              font-size: 20px;
            }

            &:hover {
              background-color: $topbarSubmenuItemHoverBgColor;
              color: $textColor;
            }

            .topbar-item-name {
              display: inline-block;
              vertical-align: middle;
            }

            .topbar-badge {
              position: absolute;
              inset-inline-end: 10px;
              top: 8px;
              padding: 2px 4px;
              display: block;
              font-size: 12px;
              line-height: 12px;
            }
          }

          > ul {
            display: none;
            list-style-type: none;
            padding-block-start: 0;
            padding-inline-end: 0;
            padding-block-end: 0;
            padding-inline-start: 18px;
            margin: 0;
            position: static;
            top: auto;
            inset-inline-start: auto;
            box-sizing: border-box;
            width: 100%;
            @include no-shadow();

            li {
              a {
                padding: $listItemPadding;
                display: block;
                width: 100%;
                box-sizing: border-box;

                span,
                img,
                .fa,
                i {
                  display: inline-block;
                  vertical-align: middle;
                }

                img {
                  width: 1.28571429em;
                  margin-inline-end: 8px;
                }

                .fa,
                i {
                  margin-inline-end: 8px;
                }
              }
            }
          }

          &.active-topmenuitem {
            > ul {
              display: block;
            }
          }

          &.profile-item {
            .profile-image {
              img {
                display: inline-block;
                vertical-align: middle;
                width: 24px;
                height: 24px;
                margin-inline-end: 8px;
              }
            }

            .profile-info {
              .topbar-item-name {
                &.profile-name {
                  vertical-align: middle;
                  font-size: $fontSize;
                }

                &.profile-role {
                  display: none;
                }
              }
            }
          }

          &.search-item {
            text-align: center;

            i {
              color: $textColor;
            }

            input {
              color: $textColor;
              border: 0 none;
              width: 90%;
              box-sizing: border-box;
              padding-inline-start: 10px;
              padding-inline-end: 2px;
            }
          }
        }

        &.topbar-menu-visible {
          display: block;
        }
      }
    }

    &.layout-wrapper-menu-active {
      .layout-sidebar {
        width: 310px;

        .layout-tabmenu {
          .layout-tabmenu-contents {
            display: block;
          }
        }
      }
    }
  }
}

@media (max-width: 640px) {
  .layout-wrapper {
    .topbar {
      .logo {
        width: 40px;
        padding-top: 15px;

        img {
          width: 30px;
          margin-top: -5px;
        }
      }

      .app-name {
        margin-inline-start: 5px;
      }
    }

    .layout-sidebar {
      width: 40px;

      .layout-tabmenu {
        .layout-tabmenu-nav {
          width: 40px;

          li {
            a {
              i {
                font-size: 20px;
              }
            }

            .layout-tabmenu-tooltip {
              inset-inline-start: 40px;
            }

            &.active-item {
              .layout-tabmenu-tooltip {
                inset-inline-start: 36px;
              }
            }
          }
        }

        .layout-tabmenu-contents {
          inset-inline-start: 40px;
        }
      }
    }

    &.layout-wrapper-menu-active {
      .layout-sidebar {
        width: 290px;
      }
    }

    .layout-main {
      margin-inline-start: 40px;
    }

    .footer {
      margin-inline-start: 40px;
    }
  }
}
