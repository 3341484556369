.p-tooltip {
  .p-tooltip-text {
    background: $tooltipBg;
    color: $tooltipTextColor;
    padding: $tooltipPadding;
    box-shadow: $inputOverlayShadow;
    border-radius: $borderRadius;
  }

  &.p-tooltip-right {
    .p-tooltip-arrow {
      border-inline-end-color: $tooltipBg;
    }
  }

  &.p-tooltip-left {
    .p-tooltip-arrow {
      border-inline-start-color: $tooltipBg;
    }
  }

  &.p-tooltip-top {
    .p-tooltip-arrow {
      border-top-color: $tooltipBg;
    }
  }

  &.p-tooltip-bottom {
    .p-tooltip-arrow {
      border-bottom-color: $tooltipBg;
    }
  }
}
