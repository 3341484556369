@use "sass:color";

.layout-wrapper {
  .layout-sidebar {
    position: fixed;
    top: 50px;
    width: 60px;
    background-color: #f5f5f5;
    height: 100%;
    -webkit-box-shadow:
      0 0 28px 0 rgba(0, 0, 0, 0.24),
      0 25px 55px 0 rgba(0, 0, 0, 0.19);
    -moz-box-shadow:
      0 0 28px 0 rgba(0, 0, 0, 0.24),
      0 25px 55px 0 rgba(0, 0, 0, 0.19);
    box-shadow:
      0 0 28px 0 rgba(0, 0, 0, 0.24),
      0 25px 55px 0 rgba(0, 0, 0, 0.19);
    z-index: 100;
    @include transition(width 0.3s);

    .layout-tabmenu {
      height: 100%;
      position: relative;

      .layout-tabmenu-nav {
        margin: 0;
        padding: 0;
        display: block;
        z-index: 100;
        width: 60px;

        li {
          list-style-type: none;
          text-align: center;
          box-sizing: border-box;
          position: relative;

          a {
            display: block;
            height: 60px;
            line-height: 60px;
            width: 100%;
            box-sizing: border-box;
            color: #757575;
            @include transition(background-color 0.3s);

            i {
              line-height: inherit;
              @include transition(color 0.3s);
              font-size: 22px;
            }

            &:hover {
              background-color: #e0e0e0;
            }
          }

          &.active-item {
            border-inline-start: 4px solid $activeMenuItemTextColor;

            a {
              background: #ffffff;
              color: $primaryColor;

              i {
                margin-inline-start: -4px;
              }
            }

            .layout-tabmenu-tooltip {
              inset-inline-start: 56px;
            }
          }

          .layout-tabmenu-tooltip {
            display: none;
            padding: 0 5px;
            position: absolute;
            inset-inline-start: 60px;
            top: 20px;
            z-index: 101;
            line-height: 1;

            .layout-tabmenu-tooltip-text {
              padding: 6px 8px;
              font-weight: 700;
              background-color: #424242;
              color: #ffffff;
              min-width: 75px;
              @include overlay-content-shadow();
            }

            .layout-tabmenu-tooltip-arrow {
              position: absolute;
              width: 0;
              height: 0;
              border-color: transparent;
              border-style: solid;
              top: 50%;
              inset-inline-start: 0;
              margin-top: -5px;
              border-block-start-width: 5px;
              border-inline-end-width: 5px;
              border-block-end-width: 5px;
              border-inline-start-width: 0;
              border-inline-end-color: #424242;
            }
          }
        }
      }

      .layout-tabmenu-contents {
        width: 250px;
        position: absolute;
        top: 0;
        inset-inline-start: 60px;
        display: none;
        background-color: #ffffff;
        height: 100%;

        .layout-tabmenu-content {
          height: 100%;
          display: none;

          &.layout-tabmenu-content-active {
            display: block;
          }

          a {
            color: $menuitemColor;
          }

          .layout-submenu-title {
            padding-block-start: 24px;
            padding-inline-end: 16px;
            padding-block-end: 0;
            padding-inline-start: 16px;
            box-sizing: border-box;
            color: #424242;
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 4px;

            .menu-pin-button {
              padding: 4px;
              margin-top: -4px;
              height: 24px;
              width: 24px;
              line-height: 24px;
              float: inline-end;
              text-align: center;
              @include border-radius(50%);
              @include transition(background-color 0.3s);

              i {
                line-height: inherit;
                @include transition(transform 0.3s);
              }

              &:hover {
                background-color: #e0e0e0;
              }
            }

            .menu-button {
              padding: 4px;
              margin-top: -4px;
              float: inline-end;
              font-size: 20px;
              @include border-radius(50%);
              @include transition(background-color 0.3s);

              &:hover {
                background-color: #e0e0e0;
              }
            }
          }

          .layout-submenu-content {
            padding: 8px 0;
            height: 100%;
            overflow: auto;

            .navigation-menu {
              margin: 0;
              padding: 0;
              padding-bottom: 150px;
              list-style-type: none;

              li {
                a {
                  display: block;
                  user-select: none;
                  cursor: pointer;
                  padding-block-start: 8px;
                  padding-inline-end: 32px;
                  padding-block-end: 8px;
                  padding-inline-start: 16px;
                  @include transition(background-color 0.3s);

                  i:first-child {
                    margin-inline-end: 8px;
                    display: inline-block;
                    vertical-align: middle;
                    font-size: 18px;
                  }

                  span {
                    display: inline-block;
                    vertical-align: middle;
                  }

                  &.rotated-icon {
                    .layout-menuitem-icon {
                      transform: rotate(90deg);

                      &:dir(rtl) {
                        transform: rotate(-90deg);
                      }
                    }
                  }

                  i.submenu-toggler {
                    float: inline-end;
                    font-size: 16px;
                    @include transition(transform 0.3s);
                  }

                  &:hover {
                    background-color: #e0e0e0;
                  }

                  &.active-menuitem-routerlink {
                    color: $activeMenuItemTextColor;

                    i {
                      color: $activeMenuItemTextColor;
                    }
                  }
                }

                &.red-badge {
                  > a {
                    .menuitem-badge {
                      background-color: #f44336;
                      color: #ffffff;
                    }
                  }
                }

                ul {
                  margin: 0;
                  padding: 0;
                  list-style-type: none;
                  overflow: hidden;

                  li {
                    a {
                      padding-block-start: 8px;
                      padding-inline-end: 32px;
                      padding-block-end: 8px;
                      padding-inline-start: 32px;
                    }

                    ul {
                      li {
                        a {
                          padding-block-start: 8px;
                          padding-inline-end: 32px;
                          padding-block-end: 8px;
                          padding-inline-start: 48px;
                        }

                        ul {
                          li {
                            a {
                              padding-block-start: 8px;
                              padding-inline-end: 32px;
                              padding-block-end: 8px;
                              padding-inline-start: 64px;
                            }

                            ul {
                              li {
                                a {
                                  padding-block-start: 8px;
                                  padding-inline-end: 32px;
                                  padding-block-end: 8px;
                                  padding-inline-start: 80px;
                                }

                                ul {
                                  li {
                                    a {
                                      padding-block-start: 8px;
                                      padding-inline-end: 32px;
                                      padding-block-end: 8px;
                                      padding-inline-start: 96px;
                                    }

                                    ul {
                                      li {
                                        a {
                                          padding-block-start: 8px;
                                          padding-inline-end: 32px;
                                          padding-block-end: 8px;
                                          padding-inline-start: 112px;
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }

                &.active-menuitem {
                  > a {
                    color: $activeMenuItemTextColor;

                    i.submenu-toggler {
                      @include rotate(-180deg);
                    }
                  }
                }
              }

              .menuitem-badge {
                position: absolute;
                inset-inline-end: 58px;
                top: 10px;
                display: inline-block;
                width: 16px;
                height: 16px;
                margin-inline-end: 6px;
                text-align: center;
                background-color: $accentColor;
                color: $accentTextColor;
                font-size: $fontSize - 2;
                font-weight: 700;
                line-height: 16px;
                @include border-radius(50%);
              }
            }
          }
        }
      }
    }

    &.layout-sidebar-dark {
      background-color: $darkSidemenuBgColor;

      .layout-tabmenu {
        .layout-tabmenu-nav {
          li {
            a {
              color: $darkMenuItemInactiveTextColor;

              &:hover {
                background-color: $darkMenuItemHoverBgColor;
                color: #f5f5f5;
              }
            }

            &.active-item {
              border-inline-start: 4px solid $darkActiveMenuItemTextColor;

              a {
                background-color: $darkMenuBgColor;

                i {
                  color: $darkMenuItemTextColor;
                }
              }
            }

            .layout-tabmenu-tooltip {
              .layout-tabmenu-tooltip-text {
                background-color: $darkActiveMenuItemTextColor;
                color: #ffffff;
              }

              .layout-tabmenu-tooltip-arrow {
                border-inline-end-color: $darkActiveMenuItemTextColor;
              }
            }
          }
        }

        .layout-tabmenu-contents {
          background-color: $darkMenuBgColor;

          .layout-tabmenu-content {
            .layout-submenu-title {
              color: $darkMenuItemTextColor;
              border-bottom-color: #82878b;

              .menu-button,
              .menu-pin-button {
                color: $darkMenuItemTextColor;

                &:hover {
                  background-color: $darkMenuItemHoverBgColor;
                }
              }
            }
          }

          .layout-submenu-content {
            .navigation-menu {
              li {
                > a {
                  color: $darkMenuItemTextColor;

                  i:first-child {
                    color: $darkMenuItemTextColor;
                  }

                  &:hover {
                    background-color: $darkMenuItemHoverBgColor;

                    i:first-child {
                      color: $darkMenuItemTextColor;
                    }
                  }

                  &.active-menuitem-routerlink {
                    color: color.adjust($darkActiveMenuItemTextColor, $lightness: 15%);

                    i {
                      color: color.adjust($darkActiveMenuItemTextColor, $lightness: 15%);
                    }
                  }
                }

                &.active-menuitem {
                  > a {
                    color: $darkActiveMenuItemTextColor;

                    i:first-child {
                      color: $darkActiveMenuItemTextColor;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
