.p-skeleton {
  background-color: $skeletonBg;
  border-radius: $borderRadius;

  &:after {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), $skeletonAnimationBg, rgba(255, 255, 255, 0));
  }

  &:dir(rtl):after {
    background: linear-gradient(-90deg, rgba(255, 255, 255, 0), $skeletonAnimationBg, rgba(255, 255, 255, 0));
  }
}
