.p-tree {
  border: $panelContentBorder;
  background: $panelContentBg;
  color: $panelContentTextColor;
  padding: $panelContentPadding;
  border-radius: $borderRadius;

  .p-tree-container {
    .p-treenode {
      padding: $treeNodePadding;

      .p-treenode-content {
        border-radius: $borderRadius;
        transition: $listItemTransition;
        padding: $treeNodeContentPadding;

        .p-tree-toggler {
          margin-inline-end: $inlineSpacing;
          @include action-icon();
        }

        .p-treenode-icon {
          margin-inline-end: $inlineSpacing;
          color: $treeNodeIconColor;
        }

        .p-checkbox {
          margin-inline-end: $inlineSpacing;

          .p-indeterminate {
            .p-checkbox-icon {
              color: $textColor;
            }
          }
        }

        &:focus {
          @include focused-listitem();
        }

        &.p-highlight {
          background: $highlightBg;
          color: $highlightTextColor;

          .p-tree-toggler,
          .p-treenode-icon {
            color: $highlightTextColor;

            &:hover {
              color: $highlightTextColor;
            }
          }
        }

        &.p-treenode-selectable:not(.p-highlight):hover {
          background: $inputListItemHoverBg;
          color: $inputListItemTextHoverColor;
        }

        &.p-treenode-dragover {
          background: $inputListItemHoverBg;
          color: $inputListItemTextHoverColor;
        }
      }
    }
  }

  .p-tree-filter-container {
    margin-bottom: $inlineSpacing;

    .p-tree-filter {
      width: 100%;
      padding-inline-end: nth($inputPadding, 2) + $primeIconFontSize;
    }

    .p-tree-filter-icon {
      inset-inline-end: nth($inputPadding, 2);
      color: $inputIconColor;
    }
  }

  .p-treenode-children {
    padding-block-start: nth($treeNodeChildrenPadding, 1);
    padding-inline-end: nth($treeNodeChildrenPadding, 2);
    padding-block-end: nth($treeNodeChildrenPadding, 3);
    padding-inline-start: nth($treeNodeChildrenPadding, 4);
  }

  .p-tree-loading-icon {
    font-size: $loadingIconFontSize;

    &.p-icon {
      width: $loadingIconFontSize;
      height: $loadingIconFontSize;
    }
  }

  .p-treenode-droppoint {
    &.p-treenode-droppoint-active {
      background-color: scale-color($highlightBg, $lightness: -20%);
    }
  }

  &.p-tree-horizontal {
    .p-treenode {
      .p-treenode-content {
        border-radius: $borderRadius;
        border: $panelContentBorder;
        background-color: $panelContentBg;
        color: $panelContentTextColor;
        padding: $treeNodeContentPadding;
        transition: $listItemTransition;

        &.p-highlight {
          background-color: $highlightBg;
          color: $highlightTextColor;

          .p-treenode-icon {
            color: $highlightTextColor;
          }
        }

        .p-tree-toggler {
          margin-inline-end: $inlineSpacing;
        }

        .p-treenode-icon {
          color: $treeNodeIconColor;
          margin-inline-end: $inlineSpacing;
        }

        .p-checkbox {
          margin-inline-end: $inlineSpacing;
        }

        .p-treenode-label:not(.p-highlight):hover {
          background-color: inherit;
          color: inherit;
        }

        &.p-treenode-selectable:not(.p-highlight):hover {
          background: $inputListItemHoverBg;
          color: $inputListItemTextHoverColor;
        }

        &:focus {
          @include focused();
        }
      }
    }
  }
}

.p-tree {
  &.p-tree-filter-presets {
    padding: 0 0.75rem 0.75rem;
    border-width: 1px 0;

    .p-treenode-droppoint {
      display: none;
    }

    .p-tree-container .p-treenode {
      padding: 0;
      .p-treenode-content {
        padding: 0;

        &:not(.p-treenode-selectable) {
          cursor: default;
        }

        &:not(.p-highlight):focus {
          background-color: $panelContentBg;
        }

        .p-treenode-label {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
        }
      }
    }
  }
}
