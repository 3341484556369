.p-divider {
  .p-divider-content {
    background-color: $panelContentBg;
  }

  &.p-divider-horizontal {
    &.p-divider-margin-0 {
      margin: 0;
      padding: 0;
    }

    margin: $dividerHorizontalMargin;
    padding: $dividerHorizontalPadding;

    &:before {
      border-top: $dividerSize $dividerColor;
    }

    .p-divider-content {
      padding: 0 $inlineSpacing;
    }
  }

  &.p-divider-vertical {
    &.p-divider-margin-0 {
      margin: 0;
      padding: 0;
    }

    margin: $dividerVerticalMargin;
    padding: $dividerVerticalPadding;

    &:before {
      border-inline-start: $dividerSize $dividerColor;
    }

    .p-divider-content {
      padding: $inlineSpacing 0;
    }
  }
}
