.p-paginator {
  justify-content: flex-end;

  &.p-paginator-first-hide {
    .p-paginator-first {
      display: none;
    }
  }

  &.p-paginator-last-hide {
    .p-paginator-last {
      display: none;
    }
  }
}
