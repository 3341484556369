.p-datatable {
  .p-sortable-column {
    outline: 0 none;

    &:focus {
      background-color: rgba($overlayColor, 0.03);
    }
  }

  .p-datatable-tbody {
    > tr {
      outline: 0 none;

      &:not(.p-highlight):focus {
        background-color: rgba($overlayColor, 0.03);
      }
    }
  }

  &.no-focus {
    .p-datatable-tbody > tr:not(.p-highlight):not(:hover):focus {
      background-color: inherit;
    }
  }
}

th.p-frozen-column {
  z-index: 1;
}

.p-datatable.p-datatable-with-overflows {
  th,
  td {
    max-width: 1px;
  }
}

.p-datatable-candidate-selection .p-datatable-scrollable-table .p-datatable-thead {
  z-index: 0;
}
