$primaryColor: #294545;
$primaryLightColor: #90a4ae;
$primaryTextColor: #cfd8dc;
$primaryTextHoverColor: #eceff1;
$accentColor: #8bc34a;
$accentLightColor: #dcedc8;
$accentTextColor: #ffffff;
$activeMenuItemTextColor: #294545;
$darkActiveMenuItemTextColor: #294545;
$darkMenuItemTextColor: #dee0e3;
$darkMenuItemInactiveTextColor: #757575;
$darkMenuItemHoverBgColor: #545454;
$darkSidemenuBgColor: #343434;
$darkMenuBgColor: #424242;

@import "../../sass/layout/_layout";
