$primaryColor: #294545;
$primaryTextColor: #ffffff;
$accentColor: #8bc34a;
$accentTextColor: #ffffff;

@import "../sass/theme/theme_compact";

// https://github.com/primefaces/primeng/issues/11587
.p-component-overlay-enter {
  animation: p-component-overlay-enter-animation 150ms forwards;
}

.p-component-overlay-leave {
  animation: p-component-overlay-leave-animation 150ms forwards;
}

@keyframes p-component-overlay-enter-animation {
  from {
    background-color: transparent;
  }
  to {
    background-color: $maskBg;
  }
}

@keyframes p-component-overlay-leave-animation {
  from {
    background-color: $maskBg;
  }
  to {
    background-color: transparent;
  }
}
