$fontSize: 14px;
$fontFamily: "Roboto", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$fontFamilyRtl: "Noto Naskh Arabic", "Roboto", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$textColor: #424242;
$textSecondaryColor: #757575;
$borderRadius: 3px;
$dividerColor: #eaeaea;
$dividerLightColor: #f8f8f8;
$iconWidth: 20px;
$iconHeight: 20px;
$iconFontSize: 20px;
$grayBgColor: #757575;
$hoverBgColor: #e8e8e8;
$hoverTextColor: #000000;
$transitionDuration: 0.3s;
$tabletBreakpoint: 991px !default;

$listItemPadding: 0.571em 0.857em;

/* Layout */
$bodyBgColor: #fcfbfb;
$menuItemHoverBgColor: #e8e8e8;
$menuitemColor: #757575;
$footerBgColor: #ffffff;
$inputBorderColor: #bdbdbd;

/* Topbar */
$topbarSubmenuBgColor: #f7f7f7;
$topbarSubmenuItemHoverBgColor: #e3e3e3;
