/* Add your customizations of theme here */

/* Tags in general case, incl. chips, badges etc -- https://www.w3.org/2021/03/04-openui-minutes.html#r01 */
@mixin -app-tag($name, $color, $background-color) {
  .app-tag-#{$name} {
    color: $color !important;
    background-color: $background-color !important;
  }

  .app-tag-icon-#{$name} {
    color: $background-color !important;
  }
}

@include -app-tag("primary", $primaryTagColor, $primaryTagBackground);
@include -app-tag("secondary", $secondaryTagColor, $secondaryTagBackground);
@include -app-tag("success", $successTagColor, $successTagBackground);
@include -app-tag("info", $infoTagColor, $infoTagBackground);
@include -app-tag("warning", $warningTagColor, $warningTagBackground);
@include -app-tag("danger", $dangerTagColor, $dangerTagBackground);
/* . */

/* Card */
.vz-card {
  /* take as much height as possible */
  @extend .flex, .flex-column;

  > .p-card-body {
    > .p-card-title {
      /* base off h3 */
      @extend h3;
      /* weird margins but ok */
      @extend .pt-4, .pl-2, .pr-2, .ml-1, .mr-1;
    }

    > .p-card-content {
      @extend .overflow-y-auto, .overflow-x-hidden;
    }
  }
}
/* . */

/* Tab view */
.vz-tabview {
  /* take as much height as possible */
  @extend .flex, .flex-column;
}
/* . */

/* Menu */
.app-menu-horizontal {
  width: auto;
  padding: 0;

  > .p-menu-list {
    display: flex;

    > .p-menuitem > .p-menuitem-content > .p-menuitem-link {
      padding: 0.75rem;

      > .p-menuitem-icon {
        width: auto;

        &:not(:has(+ .p-menuitem-text:not(:empty))) {
          margin-inline-end: 0;
        }
      }
    }
  }
}
/* . */

/* Tree select */
.app-treeselect-panel {
  .p-tree .p-tree-container .p-treenode {
    padding: 0;

    > .p-treenode-content {
      padding: 0.75rem;

      > .p-tree-toggler {
        width: 1.25rem;
        height: 1.25rem;

        &:hover,
        &:focus,
        &:active {
          background-color: transparent;
        }
      }

      > .p-checkbox-disabled {
        /* otherwise it's there but does not react to anything */
        display: none;
      }
    }
  }
}
/* . */
