/* Add your variable customizations of layout here */

/* Breakpoints */
$xxl: 2000px;

$breakpoints: (
  "sm": $sm,
  "md": $md,
  "lg": $lg,
  "xl": $xl,
  "xxl": $xxl,
);
/* . */

/* Fields */
$fieldMargin: 0.25rem;
/* . */
