/* Add your variable customizations of theme here */

/* Panel */
$panelHeaderPadding: 0.5rem;
/* . */

/* Tags in general case, incl. chips, badges etc -- https://www.w3.org/2021/03/04-openui-minutes.html#r01 */
$primaryTagColor: $badgeTextColor;
$primaryTagBackground: $badgeBg;

$secondaryTagColor: $highlightTextColor;
$secondaryTagBackground: $highlightBg;

$successTagColor: #747679;
$successTagBackground: #e3f0da;

$infoTagColor: #747679;
$infoTagBackground: #dbe3f3;

$warningTagColor: #747679;
$warningTagBackground: #fbe5d7;

$dangerTagColor: #6d534e;
$dangerTagBackground: #dea9a0;
/* . */
