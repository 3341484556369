html {
  height: 100%;
  font-size: $fontSize;
}

body {
  font-family: $fontFamily;
  font-size: $fontSize;
  color: $textColor;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  min-height: 100%;

  &:dir(rtl) {
    font-family: $fontFamilyRtl;
  }

  &.main-body {
    background-color: $bodyBgColor;
  }

  a {
    text-decoration: none;
    color: $primaryColor;
  }
}
