.p-picklist {
  .p-picklist-buttons {
    padding: $panelContentPadding;

    .p-button {
      margin-bottom: $inlineSpacing;
    }
  }

  .p-picklist-header {
    background: $panelHeaderBg;
    color: $panelHeaderTextColor;
    border: $panelHeaderBorder;
    padding: $panelHeaderPadding;
    border-bottom: 0 none;
    border-start-end-radius: $borderRadius;
    border-start-start-radius: $borderRadius;

    .p-picklist-title {
      font-weight: $panelHeaderFontWeight;
    }
  }

  .p-picklist-filter-container {
    padding: $panelHeaderPadding;
    background: $panelContentBg;
    border: $panelHeaderBorder;
    border-bottom: 0 none;

    .p-picklist-filter-input {
      padding-inline-end: nth($inputPadding, 2) + $primeIconFontSize;
    }

    .p-picklist-filter-icon {
      inset-inline-end: nth($inputPadding, 2);
      color: $inputIconColor;
    }
  }

  .p-picklist-list {
    border: $panelContentBorder;
    background: $panelContentBg;
    color: $panelContentTextColor;
    padding: $inputListPadding;
    border-end-end-radius: $borderRadius;
    border-end-start-radius: $borderRadius;

    .p-picklist-item {
      padding: $inputListItemPadding;
      margin: $inputListItemMargin;
      border: $inputListItemBorder;
      color: $inputListItemTextColor;
      background: $inputListItemBg;
      transition:
        transform $transitionDuration,
        $listItemTransition;

      &:not(.p-highlight):hover {
        background: $inputListItemHoverBg;
        color: $inputListItemTextHoverColor;
      }

      &:focus {
        @include focused-listitem();
      }

      &.p-highlight {
        color: $highlightTextColor;
        background: $highlightBg;
      }
    }

    .p-picklist-droppoint {
      &.p-picklist-droppoint-highlight {
        background-color: scale-color($highlightBg, $lightness: -20%);
      }
    }

    .p-picklist-empty-message {
      padding: $inputListItemPadding;
      color: $inputListItemTextColor;
    }
  }
}

@media screen and (max-width: $pickListBreakpoint) {
  .p-picklist {
    flex-direction: column;

    .p-picklist-buttons {
      padding: $panelContentPadding;
      flex-direction: row;

      .p-button {
        margin-inline-end: $inlineSpacing;
        margin-bottom: 0;

        &:last-child {
          margin-inline-end: 0;
        }
      }
    }

    .p-picklist-transfer-buttons {
      .pi-angle-right {
        @include icon_override("\e930");
      }

      .pi-angle-double-right {
        @include icon_override("\e92c");
      }

      .pi-angle-left {
        @include icon_override("\e933");
      }

      .pi-angle-double-left {
        @include icon_override("\e92f");
      }
    }
  }
}
