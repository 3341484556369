@use "sass:color";

.layout-wrapper {
  .topbar {
    position: fixed;
    width: 100%;
    background-color: $primaryColor;
    height: 60px;
    box-sizing: border-box;
    z-index: 100;
    -moz-box-shadow:
      0 2px 10px 0 rgba(0, 0, 0, 0.16),
      0 2px 5px 0 rgba(0, 0, 0, 0.26);
    -webkit-box-shadow:
      0 2px 10px 0 rgba(0, 0, 0, 0.16),
      0 2px 5px 0 rgba(0, 0, 0, 0.26);
    box-shadow:
      0 2px 10px 0 rgba(0, 0, 0, 0.16),
      0 2px 5px 0 rgba(0, 0, 0, 0.26);

    .logo {
      width: 60px;
      height: 60px;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      box-sizing: border-box;

      img {
        width: 35px;
        margin-top: 7px;
      }
    }

    .app-name {
      vertical-align: middle;
      display: inline-block;
      margin-top: -14px;
      height: 25px;
    }

    #topbar-menu-button {
      font-size: 28px;
      margin-block-start: 12px;
      margin-inline-end: 20px;
      margin-block-end: 0;
      margin-inline-start: 0;
      display: none;
      float: inline-end;
      color: $primaryTextColor;
      @include transition(color 0.3s);
    }

    .topbar-menu {
      float: inline-end;
      margin-block-start: 16px;
      margin-inline-end: 10px;
      margin-block-end: 0;
      margin-inline-start: 0;
      padding: 0;
      list-style-type: none;

      > li {
        float: inline-end;
        position: relative;
        margin-inline-start: 1em;
        margin-top: 2px;

        > a {
          position: relative;
          display: block;
          color: $primaryTextColor;

          .topbar-icon {
            font-size: 2em;
            @include transition(color 0.3s);
          }

          .topbar-item-name {
            display: none;
          }

          .topbar-badge {
            position: absolute;
            inset-inline-end: -5px;
            top: -5px;
            background-color: $accentColor;
            color: $accentTextColor;
            padding: 2px 4px;
            display: block;
            font-size: 12px;
            line-height: 12px;
            @include border-radius($borderRadius);
          }

          &:hover {
            color: $primaryTextHoverColor;
          }
        }

        &.profile-item {
          margin-top: -5px;

          .profile-image {
            display: inline-block;
            vertical-align: middle;

            img {
              width: 36px;
              height: 36px;
            }
          }

          .profile-info {
            display: inline-block;
            vertical-align: middle;

            .topbar-item-name {
              @include transition(color 0.3s);
              display: block;
              margin-inline-start: 4px;
              margin-inline-end: 6px;

              &.profile-name {
                font-size: $fontSize;
              }

              &.profile-role {
                font-size: $fontSize - 2;
                color: color.adjust(#ffffff, $lightness: -10%);
              }
            }
          }

          > ul {
            top: 50px;
          }
        }

        &.search-item {
          position: relative;
          color: #ffffff;
          overflow: hidden;
          margin-top: 0;

          input {
            padding: 6px;
            padding-inline-end: 20px;
          }
        }

        > ul {
          position: absolute;
          top: 42px;
          inset-inline-end: 5px;
          display: none;
          width: 250px;
          background-color: $topbarSubmenuBgColor;
          -webkit-animation-duration: 0.5s;
          -moz-animation-duration: 0.5s;
          animation-duration: 0.5s;
          list-style-type: none;
          margin: 0;
          padding: 8px 0;
          @include overlay-content-shadow();

          a {
            padding: $listItemPadding;
            display: block;
            width: 100%;
            box-sizing: border-box;
            color: $textSecondaryColor;

            i {
              margin-inline-end: 8px;
              font-size: 16px;
            }

            img {
              margin-inline-end: 8px;
            }

            i,
            img,
            span {
              vertical-align: middle;
            }

            .topbar-submenuitem-badge {
              background-color: $accentColor;
              padding: 2px 4px;
              display: block;
              font-size: 12px;
              @include border-radius($borderRadius);
              color: $accentTextColor;
              float: inline-end;
            }

            &:hover {
              background-color: $topbarSubmenuItemHoverBgColor;
              @include transition(background-color 0.3s);
            }
          }
        }

        &.active-topmenuitem {
          > ul {
            display: block;
          }
        }
      }
    }
  }
}
