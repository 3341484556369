.layout-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;

  .layout-main {
    flex: 1 1 0;
    margin-inline-start: 65px;
    padding-block-start: 80px;
    padding-inline-end: 10px;
    padding-block-end: 0px;
    padding-inline-start: 10px;
    @include transition(margin-inline-start 0.3s);
  }
}
