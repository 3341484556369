.p-button {
  font-weight: 500;
  min-width: 4rem;

  &.p-button-small {
    padding: 0.35rem 0.5rem;
  }

  &.p-button-icon-only {
    min-width: auto;
    width: 2rem;
    padding: 0.35rem;

    > .pi {
      font-size: 0.9rem;
    }
  }

  &:enabled:focus {
    background: rgba($buttonBg, 0.76);
  }

  &:enabled:active {
    background: rgba($buttonBg, 0.68);
  }

  .p-ink {
    background-color: rgba(255, 255, 255, 0.32);
  }

  &.p-button-text,
  &.p-button-outlined {
    &:enabled:focus {
      background: rgba($buttonBg, 0.12);
    }

    &:enabled:active {
      background: rgba($buttonBg, 0.16);
    }

    .p-ink {
      background-color: rgba($buttonBg, 0.16);
    }
  }

  &:disabled {
    background-color: $emphasis-lower !important;
    color: $emphasis-low !important;
    opacity: 1;

    &.p-button-text {
      background-color: transparent !important;
      color: $emphasis-low !important;
    }

    &.p-button-outlined {
      background-color: transparent !important;
      color: $emphasis-low !important;
      border-color: $emphasis-low !important;
    }
  }

  &.p-button-raised:enabled:focus {
    box-shadow: $raisedButtonShadow;
  }
}

.p-button.p-button-secondary,
.p-buttonset.p-button-secondary > .p-button,
.p-splitbutton.p-button-secondary > .p-button {
  &:enabled:focus {
    background: rgba($secondaryButtonBg, 0.76);
  }

  &:enabled:active {
    background: rgba($secondaryButtonBg, 0.68);
  }

  &.p-button-text,
  &.p-button-outlined {
    &:enabled:focus {
      background: rgba($secondaryButtonBg, 0.12);
    }

    &:enabled:active {
      background: rgba($secondaryButtonBg, 0.16);
    }

    .p-ink {
      background-color: rgba($secondaryButtonBg, 0.16);
    }
  }
}

.p-button.p-button-info,
.p-buttonset.p-button-info > .p-button,
.p-splitbutton.p-button-info > .p-button {
  &:enabled:focus {
    background: rgba($infoButtonBg, 0.76);
  }

  &:enabled:active {
    background: rgba($infoButtonBg, 0.68);
  }

  &.p-button-text,
  &.p-button-outlined {
    &:enabled:focus {
      background: rgba($infoButtonBg, 0.12);
    }

    &:enabled:active {
      background: rgba($infoButtonBg, 0.16);
    }

    .p-ink {
      background-color: rgba($infoButtonBg, 0.16);
    }
  }
}

.p-button.p-button-success,
.p-buttonset.p-button-success > .p-button,
.p-splitbutton.p-button-success > .p-button {
  &:enabled:focus {
    background: rgba($successButtonBg, 0.76);
  }

  &:enabled:active {
    background: rgba($successButtonBg, 0.68);
  }

  &.p-button-text,
  &.p-button-outlined {
    &:enabled:focus {
      background: rgba($successButtonBg, 0.12);
    }

    &:enabled:active {
      background: rgba($successButtonBg, 0.16);
    }

    .p-ink {
      background-color: rgba($successButtonBg, 0.16);
    }
  }
}

.p-button.p-button-warning,
.p-buttonset.p-button-warning > .p-button,
.p-splitbutton.p-button-warning > .p-button {
  &:enabled:focus {
    background: rgba($warningButtonBg, 0.76);
  }

  &:enabled:active {
    background: rgba($warningButtonBg, 0.68);
  }

  &.p-button-text,
  &.p-button-outlined {
    &:enabled:focus {
      background: rgba($warningButtonBg, 0.12);
    }

    &:enabled:active {
      background: rgba($warningButtonBg, 0.16);
    }

    .p-ink {
      background-color: rgba($warningButtonBg, 0.16);
    }
  }
}

.p-button.p-button-help,
.p-buttonset.p-button-help > .p-button,
.p-splitbutton.p-button-help > .p-button {
  &:enabled:focus {
    background: rgba($helpButtonBg, 0.76);
  }

  &:enabled:active {
    background: rgba($helpButtonBg, 0.68);
  }

  &.p-button-text,
  &.p-button-outlined {
    &:enabled:focus {
      background: rgba($helpButtonBg, 0.12);
    }

    &:enabled:active {
      background: rgba($helpButtonBg, 0.16);
    }

    .p-ink {
      background-color: rgba($helpButtonBg, 0.16);
    }
  }
}

.p-button.p-button-danger,
.p-buttonset.p-button-danger > .p-button,
.p-splitbutton.p-button-danger > .p-button {
  &:enabled:focus {
    background: rgba($dangerButtonBg, 0.76);
  }

  &:enabled:active {
    background: rgba($dangerButtonBg, 0.68);
  }

  &.p-button-text,
  &.p-button-outlined {
    &:enabled:focus {
      background: rgba($dangerButtonBg, 0.12);
    }

    &:enabled:active {
      background: rgba($dangerButtonBg, 0.16);
    }

    .p-ink {
      background-color: rgba($dangerButtonBg, 0.16);
    }
  }
}
