@use "sass:map";
@use "@fontsource-utils/scss" as fontsource;
@use "@fontsource/noto-naskh-arabic/scss" as NotoNaskhArabic;

@include fontsource.generator(
    $metadata: NotoNaskhArabic.$metadata,
    $subsets: (
      arabic,
    ),
    $weights: (
      400,
      500,
      700,
    )
  )
  using ($props) {
  $sizeAdjust: 120%;
  $ascentOverride: 0%;
  $descentOverride: 0%;

  @font-face {
    font-family: map.get($props, font-family);
    font-style: map.get($props, font-style);
    font-display: map.get($props, font-display);
    font-weight: map.get($props, font-weight);
    font-stretch: map.get($props, font-stretch);
    unicode-range: map.get($props, unicode-range);
    src: map.get($props, src);
    size-adjust: $sizeAdjust;
    ascent-override: $ascentOverride;
    descent-override: $descentOverride;
  }
}
