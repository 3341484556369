.p-dropdown {
  .p-inputtext,
  .p-dropdown-trigger {
    background-image: none;
    background: transparent;
  }

  .p-inputtext {
    border: 0 none;
  }

  &:not(.p-disabled).p-focus {
    box-shadow:
      inset 0 0 0 1px $inputFocusBorderColor,
      inset 0 0 0 1px $inputFocusBorderColor,
      inset 0 0 0 1px $inputFocusBorderColor,
      inset 0 0 0 1px $inputFocusBorderColor;
  }
}

.p-dropdown {
  &.overlay-only {
    border: none !important;

    .p-dropdown-trigger,
    .p-dropdown-label {
      display: none !important;
    }
  }
}

.p-dropdown-item {
  .p-ink {
    background-color: rgba($primaryColor, 0.16);
  }
}

.p-input-filled {
  .p-dropdown {
    @include filled-input-wrapper();

    .p-inputtext {
      border: 0 none;
    }
  }

  p-dropdown.ng-dirty.ng-invalid {
    .p-dropdown {
      @include invalid-filled-input-wrapper();
    }
  }
}

p-dropdown.ng-dirty.ng-invalid {
  .p-dropdown:not(.p-disabled).p-focus {
    box-shadow:
      inset 0 0 0 1px $inputErrorBorderColor,
      inset 0 0 0 1px $inputErrorBorderColor,
      inset 0 0 0 1px $inputErrorBorderColor,
      inset 0 0 0 1px $inputErrorBorderColor;
  }
}

p-dropdown.ignore-dirty-state.ng-invalid {
  .p-dropdown:not(.p-disabled) {
    box-shadow:
      inset 0 0 0 1px $inputErrorBorderColor,
      inset 0 0 0 1px $inputErrorBorderColor,
      inset 0 0 0 1px $inputErrorBorderColor,
      inset 0 0 0 1px $inputErrorBorderColor;
  }
}
