@use "sass:color";

.dashboard {
  .card {
    height: 100%;
  }

  .overview-box {
    padding: 0 !important;
    text-align: start;
    overflow: hidden;
    margin-bottom: 0px !important;
    position: relative;
    -webkit-box-shadow:
      0 2px 10px 0 rgba(0, 0, 0, 0.16),
      0 2px 5px 0 rgba(0, 0, 0, 0.26);
    -moz-box-shadow:
      0 2px 10px 0 rgba(0, 0, 0, 0.16),
      0 2px 5px 0 rgba(0, 0, 0, 0.26);
    box-shadow:
      0 2px 10px 0 rgba(0, 0, 0, 0.16),
      0 2px 5px 0 rgba(0, 0, 0, 0.26);
    min-height: 100px;

    .overview-box-name {
      font-size: 16px;
      display: block;
      width: 100%;
      margin-block-start: 8px;
      margin-inline-end: 0;
      margin-block-end: 0;
      margin-inline-start: 8px;
      color: #ffffff;
      @include opacity(0.8);
    }

    .overview-box-count {
      color: #ffffff;
      margin-block-start: -36px;
      margin-inline-end: 0;
      margin-block-end: 0;
      margin-inline-start: 8px;
      font-size: 24px;
      display: block;
      font-weight: bold;
    }

    .overview-box-badge {
      position: absolute;
      top: 8px;
      inset-inline-end: 8px;
      background: rgba(0, 0, 0, 0.12);
      @include border-radius($borderRadius);
      padding: 2px 4px;
      color: #ffffff;
    }

    img {
      position: absolute;
      bottom: 0px;
    }

    &.overview-box-1 {
      background: linear-gradient(229deg, #fd9a77, #fb827a);

      &:dir(rtl) {
        background: linear-gradient(-229deg, #fd9a77, #fb827a);
      }
    }

    &.overview-box-2 {
      background: linear-gradient(229deg, #8edead, #43c6ac);

      &:dir(rtl) {
        background: linear-gradient(-229deg, #8edead, #43c6ac);
      }
    }

    &.overview-box-3 {
      background: linear-gradient(248deg, #348ac7, #7474bf);

      &:dir(rtl) {
        background: linear-gradient(-248deg, #348ac7, #7474bf);
      }
    }

    &.overview-box-4 {
      background: linear-gradient(67deg, #f15f79, #b24592);

      &:dir(rtl) {
        background: linear-gradient(-67deg, #f15f79, #b24592);
      }
    }
  }

  .task-list {
    overflow: hidden;

    > .p-panel {
      min-height: 360px;
    }

    .p-panel-content {
      padding: 0px !important;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        padding: 10px 14px;
        border-bottom: 1px solid $dividerColor;

        &:last-child {
          border-bottom: 0 none;
        }
      }

      .p-checkbox {
        vertical-align: middle;
        margin-inline-end: 5px;
      }

      .task-name {
        vertical-align: middle;
      }

      i {
        color: $textSecondaryColor;
        float: inline-end;
        font-size: 20px;
      }
    }
  }

  .contact-form {
    overflow: hidden;

    .p-panel {
      min-height: 360px;
    }

    .p-col-12 {
      padding: 20px 10px;
    }
  }

  .contacts {
    overflow: hidden;

    > .p-panel {
      min-height: 360px;
    }

    .p-panel-content {
      padding: 0px !important;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        border-bottom: 1px solid $dividerColor;
        padding: 9px;
        width: 100%;
        box-sizing: border-box;
        text-decoration: none;
        position: relative;
        display: block;
        @include border-radius(2px);
        @include transition(background-color 0.2s);

        img {
          float: inline-start;
          margin-inline-end: 8px;
        }

        .contact-info {
          float: inline-start;

          .name {
            display: block;
            margin-top: 4px;
            font-size: 14px;
          }

          .location {
            margin-top: 4px;
            display: block;
            font-size: 12px;
            color: $textSecondaryColor;
          }
        }

        .contact-actions {
          float: inline-end;
          padding-top: 12px;

          .connection-status {
            color: #ffffff;
            padding: 2px 3px;
            vertical-align: middle;

            &.online {
              background-color: #aed581;
            }

            &.offline {
              background-color: #e57373;
            }
          }

          i {
            vertical-align: middle;
            color: $textSecondaryColor;
            margin-inline-start: 5px;
          }
        }

        &:last-child {
          border: 0;
        }
      }
    }
  }

  .activity-feed {
    text-align: center;

    h3 {
      color: #525262;
      margin-block-start: 20px;
      margin-inline-end: 0;
      margin-block-end: 5px;
      margin-inline-start: 0;
      font-weight: bold;
      font-size: 13px;
    }

    p {
      color: $textSecondaryColor;
      margin: 0;
      font-size: 13px;
    }

    .p-panel-title {
      width: 100%;
    }

    .p-col-12 {
      padding: 20px;

      span {
        display: block;
        font-weight: bold;
        color: #6a6a7d;
      }
    }

    .knob {
      width: 140px;
      height: 140px;
      line-height: 120px;
      margin-top: 20px;
      font-size: 30px;
      color: $textSecondaryColor;
      @include border-radius(50%);
      display: inline-block;

      &.income {
        border: 10px solid #1976d2;
        border-inline-start-color: color.adjust(#2196f3, $lightness: 30%);
      }

      &.tax {
        border: 10px solid #009688;
        border-inline-start-color: #80cbc4;
      }

      &.invoice {
        border: 10px solid #e91e63;
        border-inline-start-color: #f8bbd0;
      }

      &.expense {
        border: 10px solid #673ab7;
        border-inline-start-color: #b39ddb;
      }
    }
  }

  .activity-list {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      border-bottom: 1px solid $dividerColor;
      padding: 16px 8px;

      .count {
        font-size: 20px;
        color: #ffffff;
        background-color: #007be5;
        font-weight: 700;
        padding: 0.25em 0.5em;
        display: inline-block;
        border-radius: $borderRadius;
      }
    }
  }

  .user-card {
    @include border-radius($borderRadius);

    .user-card-header {
      height: 100px;
      overflow: hidden;
      background-color: #545b61;

      img {
        width: 100%;
      }
    }

    .user-card-content {
      height: 340px;
      background-color: #ffffff;

      img {
        margin-block-start: -40px;
        margin-inline-end: 0;
        margin-block-end: 0;
        margin-inline-start: 24px;
      }

      .p-button {
        float: inline-end;
        width: 48px;
        height: 48px;
        margin-block-start: -24px;
        margin-inline-end: 24px;
        margin-block-end: 0;
        margin-inline-start: 0;
      }

      .user-card-name {
        font-size: 20px;
        color: #ffffff;
        position: relative;
        top: -70px;
        margin-inline-start: 110px;
        font-weight: 700;
      }

      .user-detail {
        text-align: start;

        ul {
          padding-block-start: 0px;
          padding-inline-end: 0;
          padding-block-end: 32px;
          padding-inline-start: 0;
          margin: 0;
          list-style-type: none;

          li {
            padding: 16px 24px;
            border-top: 1px solid $dividerColor;

            &:last-child {
              border-bottom: 1px solid $dividerColor;
            }

            i {
              font-size: 24px;
              margin-inline-end: 8px;
              width: 32px;
              vertical-align: middle;
              color: $textSecondaryColor;
            }

            .project-title {
              font-weight: 700;
              margin-inline-end: 8px;
            }

            .project-detail {
              color: $textSecondaryColor;
            }

            .project-progressbar {
              display: inline-block;
              width: 100px;
              background-color: #545b61;
              float: inline-end;
              margin-top: 12px;

              .project-progressbar-value {
                background-color: $accentColor;
                height: 4px;
              }
            }
          }
        }
      }
    }
  }

  .chat {
    .p-panel-content {
      padding: 0 !important;
    }
    ul {
      padding: 12px;
      margin: 0;
      list-style-type: none;

      li {
        padding: 6px 0;
        img {
          width: 36px;
          float: inline-start;
        }

        span {
          padding: 6px 12px;
          float: inline-start;
          display: inline-block;
          margin: 4px 0;
          @include border-radius(10px);
        }

        &.message-from {
          img,
          span {
            float: inline-start;
          }

          img {
            margin-inline-end: 8px;
          }

          span {
            background-color: $primaryTextColor;
            color: #000000;
          }
        }

        &.message-own {
          img,
          span {
            float: inline-end;
          }

          img {
            margin-inline-start: 8px;
          }

          span {
            background: $accentLightColor;
            color: #000000;
          }
        }
      }
    }

    .new-message {
      height: 40px;
      border-top: 1px solid #dce2e7;
      color: #afafc0;

      .message-attachment {
        display: inline-block;
        border-inline-end: 1px solid #dce2e7;
        width: 40px;
        line-height: 40px;
        height: 100%;
        text-align: center;

        i {
          line-height: inherit;
          font-size: 24px;
        }
      }

      .message-input {
        position: relative;
        top: -4px;
        width: calc(100% - 100px);
        display: inline-block;

        input {
          border: 0 none;
          font-size: 14px;
          width: 100%;
          background-color: transparent;
          outline: 0 none;
          color: $textSecondaryColor;
        }
      }
    }
  }

  .timeline {
    height: 100%;
    box-sizing: border-box;

    > .p-grid {
      .p-col-3 {
        font-size: 14px;
        position: relative;
        border-inline-end: 1px solid #bdbdbd;

        i {
          background-color: #ffffff;
          font-size: 28px;
          position: absolute;
          top: 6px;
          inset-inline-end: -15px;
        }
      }

      .p-col-9 {
        padding-inline-start: 1.5em;
        .event-owner {
        }

        .event-text {
          color: $textSecondaryColor;
          font-size: 14px;
          display: block;
          padding-bottom: 20px;
        }

        .event-content {
          img {
            width: 100%;
          }
        }
      }
    }
  }

  .chart-panel .p-panel-content {
    overflow: auto;
  }
}
