.p-autocomplete,
.p-autocomplete-input {
  width: 100%;
}

.p-autocomplete {
  .p-autocomplete-multiple-container {
    &:not(.p-disabled).p-focus {
      box-shadow:
        inset 0 0 0 1px $inputFocusBorderColor,
        inset 0 0 0 1px $inputFocusBorderColor,
        inset 0 0 0 1px $inputFocusBorderColor,
        inset 0 0 0 1px $inputFocusBorderColor;
    }
  }
}

.p-input-filled {
  .p-autocomplete {
    .p-autocomplete-multiple-container {
      @include filled-input-wrapper();
    }
  }
}

.p-float-label {
  .p-autocomplete-multiple-container {
    .p-autocomplete-token {
      padding: 0.25rem 1rem;
    }
  }
}

.p-input-filled {
  .p-float-label {
    .p-autocomplete {
      .p-autocomplete-multiple-container {
        .p-autocomplete-token {
          padding-top: 0;
          padding-bottom: 0;

          .p-autocomplete-token-icon {
            font-size: 75%;
          }
        }

        .p-autocomplete-input-token {
          padding: 0;
        }
      }
    }
  }

  p-autocomplete.ng-dirty.ng-invalid {
    .p-autocomplete > .p-inputtext {
      @include invalid-filled-input();
    }

    .p-autocomplete .p-autocomplete-multiple-container {
      @include invalid-filled-input-wrapper();
    }
  }
}

p-autocomplete.ng-dirty.ng-invalid {
  .p-autocomplete > .p-inputtext {
    &:enabled:focus {
      box-shadow:
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor;
    }
  }

  .p-autocomplete .p-autocomplete-multiple-container {
    &:not(.p-disabled).p-focus {
      box-shadow:
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor;
    }
  }
}
