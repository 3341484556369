.layout-wrapper {
  .footer {
    margin-inline-start: 65px;
    padding: 0.5em;
    @include transition(margin-inline-start 0.3s);

    .footer-text-left {
      float: inline-start;
    }

    .footer-text-right {
      color: $textSecondaryColor;
      float: inline-end;

      span {
        vertical-align: middle;
        display: inline-block;
      }
    }
  }
}
