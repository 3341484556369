.exception-body {
  background-color: #f5f5f5;
  padding-top: 200px;
  height: auto;
  background-size: contain;

  * {
    @include border-box-sizing();
  }

  .exception-panel {
    background-color: #ffffff;
    width: 400px;
    height: 323px;
    margin: 0 auto;
    padding-bottom: 20px;
    -webkit-box-shadow:
      0 12px 15px 0 rgba(0, 0, 0, 0.24),
      0 17px 50px 0 rgba(0, 0, 0, 0.19);
    -moz-box-shadow:
      0 12px 15px 0 rgba(0, 0, 0, 0.24),
      0 17px 50px 0 rgba(0, 0, 0, 0.19);
    box-shadow:
      0 12px 15px 0 rgba(0, 0, 0, 0.24),
      0 17px 50px 0 rgba(0, 0, 0, 0.19);

    .p-button {
      background-color: #ffffff;
      display: block;
      @include no-shadow();

      &:enabled:not(:focus):hover {
        background-color: $hoverBgColor;
      }

      margin-block-start: 100px;
      margin-inline-end: 0;
      margin-block-end: 0;
      margin-inline-start: 30px;
    }

    > img:dir(rtl) {
      transform: scaleX(-1);
    }
  }

  .exception-band {
    height: 80px;
    margin-top: -160px;
    z-index: 1000;

    .exception-content {
      margin: 0 auto;
      width: 400px;
      position: relative;
      padding: 15px 30px;

      h3 {
        padding: 0;
        margin: 0;
      }

      p {
        padding: 0;
        margin: 0;
      }

      img {
        position: absolute;
        width: 48px;
        inset-inline-end: 30px;
        top: 16px;
      }
    }
  }

  &.error-body {
    background: url("../../layout/images/exception/error-image.png") no-repeat 0 -100px;

    .exception-panel {
      img {
        width: 100%;
      }

      .p-button {
        color: #f44336;

        &:hover {
          @include hover-element();
          color: #f44336;
        }
      }
    }

    .exception-band {
      background-color: #f44336;

      .exception-content {
        color: #ffebee;
      }
    }
  }

  &.pagenotfound-body {
    background: url("../../layout/images/exception/404-image.png") no-repeat 0 -100px;

    .exception-panel {
      img {
        width: 200px;
        margin: 0 auto;
        display: block;
        position: relative;
        top: 4px;
      }

      .p-button {
        color: #00796b;
        margin-top: 105px;

        &:hover {
          @include hover-element();
          color: #00796b;
        }
      }
    }

    .exception-band {
      background-color: #00796b;

      .exception-content {
        color: #b2dfdb;
      }
    }
  }

  &.accessdenied-body {
    background: url("../../layout/images/exception/access-image.png") no-repeat 0 -100px;

    .exception-panel {
      img {
        width: 300px;
        display: block;
        margin: 0 auto;
        position: relative;
        top: 6px;
      }

      .p-button {
        color: #f57c00;
        margin-top: 110px;

        &:hover {
          @include hover-element();
          color: #f57c00;
        }
      }
    }

    .exception-band {
      background-color: #f57c00;

      .exception-content {
        color: #ffe0b2;
      }
    }
  }
}

.unsubscribe-body {
  color: black;
  width: 960px;
  margin: 0 auto;
  padding: 50px 0;
  max-height: 90vh;

  #wrapper {
    width: 960px;
    margin: 0 auto;
  }

  #unsubPanel {
    width: 100%;
    height: 400px;
    margin: auto;
  }

  #unsubForm {
    margin: auto;
    position: relative;
    top: 25%;
    height: 25%;
    margin-bottom: 3px;

    a,
    .unsub-panel {
      margin: auto;
      text-align: center;
      inset-inline-start: 50%;
      transform: translate(-50%, 0);
      position: absolute;

      &:dir(rtl) {
        transform: translate(50%, 0);
      }
    }

    .logo {
      display: block;
      white-space: nowrap;

      .logo-img {
        width: 35px;
        vertical-align: middle;
        margin-inline-end: 10px;
      }

      .logo-txt {
        height: 25px;
        vertical-align: middle;
      }
    }
  }

  .unsub-logo {
    background: #00796b;
    padding: 10px;
    border-radius: 3px;
    height: 15% !important;
    width: 25%;
  }

  .unsub-panel {
    width: max-content;

    p {
      font-size: x-large;
      display: block;
      margin-top: 20px;
    }

    small {
      display: block;
      padding: 5px 0px;
    }

    p-button {
      display: block;
      margin-top: 10px;

      button {
        background-color: #f57c00;
      }
    }
  }
}

@media (min-width: 1441px) {
  .exception-body {
    background-size: contain !important;
  }
}

@media (max-width: 640px) {
  .exception-body {
    .exception-panel {
      width: 350px;
    }

    .exception-band {
      margin-top: -180px;

      .exception-content {
        img {
          inset-inline-end: 48px;
        }
      }
    }

    &.error-body {
      .exception-panel {
        .p-button {
          margin-top: 111px;
        }
      }
    }

    &.pagenotfound-body {
      .exception-panel {
        img {
          width: 175px;
        }

        .p-button {
          margin-top: 117px;
        }
      }
    }

    &.accessdenied-body {
      .exception-panel {
        img {
          width: 262px;
        }

        .p-button {
          margin-top: 120px;
        }
      }
    }
  }

  .unsubscribe-body {
    color: black;
  }
}

@media (max-width: 480px) {
  .exception-body {
    .exception-band {
      .exception-content {
        img {
          inset-inline-end: 24px;
        }

        width: 350px;
        padding: 15px 10px;
      }
    }
  }
}
